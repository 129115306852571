import React from "react";
import ErrorPage from "../components/ErrorPage";


const NotFoundPage = () => {
  return (
    <ErrorPage
      error_code={"404: Not Found"}
      error_desc={"Requested Page Not Found."}>
    </ErrorPage>
  );
}

export default NotFoundPage;

